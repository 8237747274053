export const Actions = {
    SetCurrentPage: "SetCurrentPage",
    SetLoggedIn: "SetLoggedIn",
    SetAccessToken: "SetAccessToken",
    SetMelUserData: "SetMelUserData",
    SetUserData: "SetUserData",
    SetViewing: "SetViewing",
    SetInnovationManagement: "SetInnovationManagement",
    SetPreviewedInnovation: "SetPreviewedInnovation",
    SetStep: "SetStep",
    SetResults: "SetResults",
    SetAccordionData: "SetAccordionData",
    SetDescriptionValues: "SetDescriptionValues",
    SetBenefitImpactValues: "SetBenefitImpactValues",
    SetContextValues: "SetContextValues",
    SetEvidenceValues: "SetEvidenceValues",
    SetIntellectualPropertyValues: "SetIntellectualPropertyValues",
    SetInterventionsValues: "SetInterventionsValues",
    SetInvestmentValues: "SetInvestmentValues",
    SetReadinessValues: "SetReadinessValues",
    SetStakeholdersValues: "SetStakeholdersValues",
    SetInnovations: "SetInnovations",
    SetEditingInnovation: "SetEditingInnovation",
}